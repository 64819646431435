<template>
    <router-view />
</template>
  
<script>
export default {
    name: 'App',
    created() {
        const html = document.querySelector('html');
        const theme = localStorage.getItem('theme');
        if (theme) html.dataset.bsTheme = theme;
        else {
            const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
            if (darkModeMediaQuery.matches) html.dataset.bsTheme = 'dark';
            else html.dataset.bsTheme = 'light';
        }
    }
}
</script>
  
<style></style>