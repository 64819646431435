import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import * as globals from '../../globals.json'

import HomePage from './pages/HomePage.vue'
import TerminalPage from './pages/TerminalPage.vue'
import MaintenancePage from './pages/MaintenancePage.vue'
import PageNotFound from '../../components/PageNotFound.vue'

const router = createRouter({
    history: createWebHistory("/"),
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomePage,
            // component: MaintenancePage,
        },
        {
            path: '/term',
            name: 'terminal',
            component: TerminalPage,
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'NotFound',
            component: PageNotFound,
        },
    ]
})

const app = createApp(App)
app.config.globalProperties.$globals = globals;

// Translations
import en from './translations/en.json'
import uk from './translations/uk.json'
app.config.globalProperties.$langs = { en, uk };
app.config.globalProperties.$t = (lang, key) => {
    const translation = app.config.globalProperties.$langs[lang];
    return translation[key] || key;
};

app.use(router)
app.mount('#app')