<template>
    <header>
        <nav class="navbar">
            <div class="container-fluid px-3 py-1">
                <div class="d-md-none">
                    <router-link to="/term" class="btn btn-circle text-decoration-none me-2">
                        <i class="fas fa-terminal"></i>
                    </router-link>
                </div>

                <div class="d-none d-md-block">
                    <a class="nav-link me-3" href="#about">{{ t('about') }}</a>
                    <a class="nav-link me-3" href="#timeline">{{ t('timeline') }}</a>
                    <a class="nav-link me-3" href="#projects">{{ t('projects') }}</a>
                    <a class="nav-link me-3" href="#blog">{{ t('blog') }}</a>
                    <a class="nav-link" href="#contact">{{ t('contact') }}</a>
                </div>

                <form class="d-flex">
                    <router-link to="/term" class="navbar-text nav-link text-decoration-none me-2 d-none d-md-inline-block">
                        <i class="fas fa-terminal"></i> {{ t('terminal') }}
                    </router-link>

                    <!-- language selector -->
                    <div class="dropdown">
                        <button class="btn btn-circle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <i class="fas fa-language"></i>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                            <li v-for="(lang, i) in $langs" :key="i">
                                <a class="dropdown-item" href="#" @click="language = lang['code']">{{ lang['lang'] }}</a>
                            </li>
                        </ul>
                    </div>

                    <!-- dark mode toggle -->
                    <a class="btn btn-circle" @click="toggleDarkMode" title="Dark mode toggle">
                        <i :class="darkModeSwitch + ' fa-lg'"></i>
                    </a>
                </form>
            </div>
        </nav>
    </header>
    <div class="gradient-1">
        <div class="container-md">
            <div class="row">
                <div class="col-sm-6 my-auto py-5 intro px-4 text-sm-start text-center">
                    <span class="intro-1">{{ t('intro-1') }}</span>
                    <span class="intro-2">&nbsp;{{ t('intro-2') }}</span>
                    <p class="intro-3">{{ t('intro-3') }}</p>
                    <a href="#contact" class="btn btn-outline-accent px-5">{{ t('contact') }}</a>
                </div>
                <div class="col-sm-6 align-self-end">
                    <img src="/img/portrait.png" class="img-fluid pt-3 px-sm-3 px-md-1 px-5" style="filter: grayscale(80%);">
                </div>
            </div>
        </div>
    </div>
    <div class="solid-bg px-3" id="about">
        <div class="container-md">
            <h2 class="block-title pb-1">{{ t('about') }}</h2>
            <p class="about-text pb-4">
                {{ t('about-text') }}
            </p>
            <h3 class="block-title text-center">{{ t('tech-stack') }}</h3>
            <div class="row g-3">
                <div class="col-6 col-md-4 col-lg-3" v-for="(technology, i) in technologies" :key="i">
                    <div class="tech-card py-4">
                        <img :src="technology.img" class="img-fluid tech-icon mx-auto" :alt="technology.name">
                        <p class="card-text text-center">{{ technology.name }}</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="tech-card py-3 text-center">
                        {{ t('tech-stack-more') }}
                        <i class="fas fa-heart"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="gradient-2 px-3 py-4" id="timeline">
        <div class="container-md">
            <h2 class="block-title pb-4">{{ t('timeline') }}</h2>
            <p>
                <ul class="timeline">
                    <li class="timeline-item" v-for="item in timelines" :key="item.name">
                        <h4 class="timeline-title">
                            <i :class="item.icon"></i>
                            {{ t(item.name) }}<br/>
                            <span class="timeline-date">
                                <i class="fa fa-calendar"></i>
                                {{ item.date }}
                            </span>
                        </h4>
                        <div class="timeline-body">
                            <p>{{ t(item.desc) }}</p>
                        </div>
                    </li>
                </ul>
            </p>
        </div>
    </div>
    <div class="solid-bg px-3" id="projects">
        <div class="container-md">
            <h2 class="block-title pb-4">{{ t('projects') }}</h2>
            <div class="row g-2 mb-4 align-items-stretch">
                <div class="col-md-6" v-for="(proj, i) in projects" :key="i">
                    <div class="card project">
                        <img :src="proj.img" class="card-img-top">
                        <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <div>
                                    <span class="proj-name">{{ t(proj.name) }}</span><br />
                                    <span class="proj-desc">{{ t(`${proj.name}-desc`) }}</span>
                                </div>
                                <div class="d-flex align-items-center ms-1">
                                    <a :href="proj.url" class="btn btn-outline-accent px-4">{{ t('visit') }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card project h-100 d-flex flex-column">
                        <div class="my-auto text-center">
                            <div class="py-md-1 py-5">
                                <i class="fab fa-github fa-6x mb-3"></i>
                                <p class="card-text px-3">{{ t('visit-github') }}</p>
                                <a href="https://github.com/prevter" class="btn btn-outline-accent px-4">
                                    {{ t('visit') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="gradient-3 px-3 py-4" id="blog">
        <!-- <div class="container-md">
            <h2 class="block-title pb-4">{{ t('blog') }}</h2>
            <div v-if="blogPosts">
                <div v-for="(post, i) in blogPosts" :key="i">
                    <h4 class="post-title">
                        <a :href="post.url" class="text-decoration-none">{{ post.title }}</a>&nbsp;
                        <span class="post-date">{{ new Date(post.timestamp).toLocaleString() }}</span>
                    </h4>
                    <p class="post-content">
                        {{ post.content }}
                        <br />
                        {{ t('read-more') }}
                    </p>
                    <hr />
                </div>
                <a href="/blog/" class="btn btn-outline-accent px-4 mb-2">{{ t('more') }}</a>
            </div>
            <div v-else-if="blogLoading">
                <div class="spinner-border text-accent" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div v-else-if="blogError">
                <p class="text-danger">{{ blogError }}</p>
            </div>
            <p><i class="fa fa-tools"></i> {{ t('indev') }}</p>
        </div> -->
        <div class="my-5 py-2"></div>
    </div>
    <div class="solid-bg px-3" id="contact">
        <div class="container-md">
            <h2 class="block-title pb-4">{{ t('feedback') }}</h2>
            <p v-html="t('feedback-text')"></p>
            <p class="pb-4">
                <a href="mailto:prevter@gmail.com" class="text-decoration-none" target="_blank"><i
                        class="fas fa-envelope"></i> prevter@gmail.com</a><br />
                <a href="https://t.me/prevter" class="text-decoration-none" target="_blank"><i class="fab fa-telegram"></i>
                    @prevter</a><br />
                <a href="https://www.linkedin.com/in/prevter/" class="text-decoration-none" target="_blank"><i
                        class="fab fa-linkedin"></i> in/prevter</a><br />
                <a href="https://github.com/prevter" class="text-decoration-none" target="_blank"><i
                        class="fab fa-github"></i> github.com/prevter</a><br />
            </p>
            <form class="row g-1" @submit="$event.preventDefault(); $event.stopPropagation(); submitContactForm($event);">
                <div class="col-md-12">
                    <input type="text" class="form-control" id="validationCustom01" required :placeholder="t('name')">
                </div>
                <div class="col-md-12">
                    <input type="email" class="form-control" id="validationCustom02" required :placeholder="t('email')">
                </div>
                <div class="col-md-12">
                    <textarea class="form-control" id="validationCustom03" required :placeholder="t('message')"
                        rows="6"></textarea>
                </div>
                <div class="col-12 d-flex mt-2">
                    <button class="btn btn-outline-accent px-4" type="submit" :disabled="contactLoading">{{ t('submit')
                    }}</button>
                    <div class="spinner-border text-accent ms-3 align-self-center" role="status" v-if="contactLoading">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="text-danger ms-3 align-self-center" v-if="contactError">{{ contactError }}</div>
                    <div class="text-success ms-3 align-self-center" v-if="contactSuccess">{{ t('success') }}</div>
                </div>
            </form>
        </div>
    </div>
    <footer class="text-center py-3">
        &copy; <b>{{ new Date().getFullYear() }} Prevter</b>. {{ t('footer-1') }}<br />
        <small>
            {{ t('footer-2') }} <i class="fas fa-heart"></i>
            {{ t('footer-3') }} <b><a href="https://getbootstrap.com/" style="text-decoration: none;">Bootstrap</a></b>
            {{ t('footer-4') }} <b><a href="https://vuejs.org/" style="text-decoration: none;">Vue.js</a></b>.
        </small>
    </footer>
</template>

<script>

export default {
    name: 'HomePage',
    data() {
        const moon = 'fas fa-moon';
        const sun = 'fas fa-sun';
        const html = document.querySelector('html');
        let current = html.dataset.bsTheme === 'dark' ? sun : moon;

        // check local storage for language
        const lang = localStorage.getItem('lang');
        let currentLang = 'en';
        if (lang) currentLang = lang;
        else {
            // check browser language
            const browserLang = navigator.language.split('-')[0];
            if (Object.keys(this.$langs).includes(browserLang))
                currentLang = browserLang;
        }

        return {
            darkModeSwitch: current,
            language: currentLang,
            technologies: [{
                name: 'JavaScript',
                img: '/img/javascript.svg'
            }, {
                name: 'Node.js',
                img: '/img/nodejs.svg'
            }, {
                name: 'Express.js',
                img: '/img/expressjs.svg'
            }, {
                name: 'Vue.js',
                img: '/img/vuejs.svg'
            }, {
                name: 'Bootstrap',
                img: '/img/bootstrap.svg'
            }, {
                name: 'HTML/CSS',
                img: '/img/html.svg'
            }, {
                name: '.NET',
                img: '/img/dotnet.svg'
            }, {
                name: 'C++',
                img: '/img/cpp.svg'
            }, {
                name: 'Python',
                img: '/img/python.svg'
            }, {
                name: 'Kotlin',
                img: '/img/kotlin.svg'
            }, {
                name: 'MongoDB',
                img: '/img/mongodb.svg'
            }, {
                name: 'SQL',
                img: '/img/sql.svg'
            }],
            projects: [{
                name: 'floattool',
                img: '/img/floattool.png',
                url: '/floattool/'
            }, {
                name: 'pulsebyte',
                img: '/img/pulsebyte.png',
                url: 'https://github.com/prevter/pulsebyte'
            }, 
            //{
            //    name: 'gdopenhack',
            //    img: '/img/gdopenhack.png',
            //    url: 'https://github.com/prevter/gdopenhack'
            //}, 
            {
                name: 'storage-system',
                img: '/img/storage-system.png',
                url: 'https://github.com/prevter/storagesystem'
            }, 
            {
                name: 'wordle',
                img: '/img/wordle.png',
                url: '/wordle/'
            }, {
                name: 'roulette',
                img: '/img/roulette.png',
                url: '/roulette/'
            }],
            timelines: [{
                name: 'birthday',
                icon: 'fas fa-birthday-cake',
                date: '25.02.2004',
                desc: 'birthday-desc'
            },{
                name: 'school',
                icon: 'fas fa-school',
                date: '2010-2021',
                desc: 'school-desc'
            },{
                name: 'first-code',
                icon: 'fas fa-code',
                date: '2014',
                desc: 'first-code-desc'
            },{
                name: 'university',
                icon: 'fas fa-graduation-cap',
                date: '2021-2026',
                desc: 'university-desc'
            }],
            blogPosts: null,
            blogLoading: true,
            blogError: null,
            contactLoading: false,
            contactError: null,
            contactSuccess: false
        }
    },
    created() {
        // this.loadBlogPosts();
    },
    watch: {
        language(newVal) {
            localStorage.setItem('lang', newVal);
            // this.loadBlogPosts();
        }
    },
    methods: {
        toggleDarkMode() {
            const html = document.querySelector('html');
            if (html.dataset.bsTheme === 'dark') {
                html.dataset.bsTheme = 'light';
                this.darkModeSwitch = 'fas fa-moon';
            }
            else {
                html.dataset.bsTheme = 'dark';
                this.darkModeSwitch = 'fas fa-sun';
            }
            localStorage.setItem('theme', html.dataset.bsTheme);
        },
        submitContactForm(e) {
            const form = e.target;
            const name = form[0].value;
            const email = form[1].value;
            const message = form[2].value;

            const data = {
                name,
                email,
                message
            };

            this.contactLoading = true;
            this.contactError = null;
            this.contactSuccess = false;

            fetch(`${this.$globals.api}/home/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            }).then((r) => {
                if (r.status === 200) {
                    this.contactError = null;
                    this.contactSuccess = true;
                    form.reset();
                }
                else {
                    this.contactError = this.t('error');
                }
            }).catch(() => {
                this.contactError = this.t('error');
            }).finally(() => {
                this.contactLoading = false;
            });
        },
        loadBlogPosts() {
            this.blogLoading = true;
            this.blogError = null;
            this.blogPosts = null;
            fetch(`${this.$globals.api}/blog/latest?lang=${this.language}`)
                .then((r) => r.json())
                .then((r) => {
                    this.blogPosts = r.posts;
                    for (let i = 0; i < this.blogPosts.length; i++) {
                        if (this.blogPosts[i].content.length > 200) {
                            let lastSpace = this.blogPosts[i].content.lastIndexOf(' ', 200);
                            if (lastSpace === -1) lastSpace = 200;
                            this.blogPosts[i].content = this.blogPosts[i].content.substring(0, lastSpace) + '...';
                        }
                        this.blogPosts[i].url = `/blog/${this.language}/post/${this.blogPosts[i].id}`;
                    }
                })
                .catch(() => {
                    this.blogError = this.t('error');
                })
                .finally(() => {
                    this.blogLoading = false;
                });
        },
        t(key) {
            return this.$t(this.language, key);
        }
    }
}
</script>

<style>
:root {
    --background-1: #f6fdf7;
    --background-2: #dbdbdb;
    --background-3: #ffffff;
    --primary: #2d2e30;
    --secondary: #9a9e9a;
    --accent: #bbbb38;
}

[data-bs-theme='dark'] {
    --background-1: #2d2e30;
    --background-2: #31333b;
    --background-3: #23242B;
    --primary: #f6fdf7;
    --secondary: #9a9e9a;
    --accent: #bbbb38;
}

#app {
    font-family: 'Anonymous Pro', monospace;
    background: var(--background-1);
    color: var(--primary);
}

.container-md {
    max-width: 960px;
}

header {
    z-index: 1000;
    background: var(--background-1);
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.20);
    font-size: 1rem;
}

.nav-link {
    color: var(--secondary);
    font-weight: 400;
    transition: all 0.2s ease-in-out;
    display: inline-block;
}

footer {
    background: var(--background-1);
    box-shadow: 0px -6px 12px 0px rgba(0, 0, 0, 0.20);
    line-height: 1.2;
}

.gradient-1 {
    background: radial-gradient(142.62% 90.01% at 73.25% 47.69%, var(--background-2) 0%, var(--background-3) 100%);
}

.gradient-2 {
    background: radial-gradient(116.68% 113.47% at 17.13% 22.49%, var(--background-2) 0%, var(--background-3) 100%);
}

.gradient-3 {
    background: radial-gradient(109.18% 104.57% at 80.46% 66.79%, var(--background-2) 0%, var(--background-3) 100%);
}

.intro-1 {
    color: var(--primary);
    font-size: calc(0.5rem + 1.5vw);
    font-weight: 700;
}

.intro-2 {
    color: var(--accent);
    font-size: calc(1.5rem + 1vw);
    font-weight: 700;
    letter-spacing: -2px;
    word-spacing: -8px;
}

.intro {
    line-height: 1.2;
}

.intro-3 {
    color: var(--primary);
    font-size: calc(1rem + 0.5vw);
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 1.2;
}

.btn-outline-accent {
    color: var(--accent);
    border-color: var(--accent);
    border-width: 3px;
    transition: all 0.2s ease-in-out;
    font-weight: 700;
    line-height: 1.2;
}

.btn-outline-accent:hover {
    color: var(--primary);
    background: var(--accent);
}

.btn-outline-accent:focus {
    color: var(--primary) !important;
    background: var(--accent) !important;
    border: 3px solid var(--accent) !important;
}

.solid-bg {
    background: var(--background-1);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.40);
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.block-title {
    color: var(--primary);
    font-size: calc(1.5rem + 1.5vw);
    font-weight: 700;
    letter-spacing: -2px;
    word-spacing: -8px;
}

.about-text {
    font-family: 'Cormorant Garamond', serif;
    font-size: calc(1rem + 0.5vw);
    font-weight: 400;
    line-height: 1.2;
    color: var(--primary);
}

.about-text::before {
    content: '-';
    font-size: calc(3rem + 1.5vw);
    line-height: 0.1em;
    color: var(--accent);
    vertical-align: -0.1em;
    padding-right: 0.1em;
}

.tech-card {
    border-radius: 0.5rem;
    background: var(--background-2);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
    transition: all 0.2s ease-in-out;
}

.tech-card:hover {
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
    background: var(--background-3);
}

.tech-icon {
    display: block;
    max-width: 3rem;
    max-height: 3rem;
    margin: 0.5rem;
    transition: all 0.2s ease-in-out;
    border-radius: 0.5rem;
}

.project {
    font-family: 'Inter', sans-serif;
    line-height: 1.2;
    border-radius: 0.5rem;
    background: var(--background-2);
    color: var(--primary);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
    transition: all 0.2s ease-in-out;
}

.project:hover {
    transform: scale(1.01);
    transition: all 0.2s ease-in-out;
    background: var(--background-3);
}

.proj-name {
    font-size: calc(1rem + 0.2vw);
    font-weight: 700;
    color: var(--primary);
    line-height: 1;
    letter-spacing: -0.3px;
}

.proj-desc {
    font-size: calc(0.75rem + 0.2vw);
    font-weight: 400;
    color: var(--secondary);
    line-height: 1;
    letter-spacing: -0.27px;
}

.post-title {
    font-size: calc(1.2rem + 0.2vw);
    font-weight: 700;
    color: var(--primary);
    line-height: 1;
    letter-spacing: -0.3px;
}

.post-date {
    font-size: calc(0.75rem + 0.2vw);
    font-weight: 400;
    color: var(--secondary);
    line-height: 1.2;
    letter-spacing: -0.27px;
}

.post-content {
    font-size: calc(1rem + 0.2vw);
    font-weight: 400;
    color: var(--primary);
    font-family: 'Cormorant Garamond', serif;
    line-height: 1.1;
    letter-spacing: -0.2px;
}

.timeline {
    list-style-type: none;
    position: relative;
    padding-left: 1rem;
}

.timeline:before {
    content: ' ';
    background: var(--primary);
    display: inline-block;
    position: absolute;
    left: 21px;
    width: 2px;
    height: 100%;
    z-index: 400;
    border-radius: 1rem;
}

.timeline-item {
    margin: 0px 48px;
    padding-top: 20px;
    font-family: 'Inter', sans-serif;
}

.timeline-item:before {
    content: ' ';
    background: var(--accent);
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 1px solid var(--primary);
    left: 10px;
    width: 24px;
    height: 24px;
    z-index: 400;
    box-shadow: 0px 0px 32px 0px var(--accent);
}

.timeline-title {
    font-weight: bold;
}

.timeline-date {
    font-size: calc(0.75rem + 0.2vw);
    font-weight: 400;
    color: var(--secondary);
    line-height: 1.2;
    letter-spacing: -0.27px;
}

</style>