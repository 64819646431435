<template>
    <textarea class="form-control" rows="1" style="opacity: 0; position: absolute; top: -100px; left: -100px;"
        id="mobileBox"></textarea>
    <div class="terminal" @click="focusMobile">
        <div class="cursor" :style="{ top: cursorY + 'px', left: cursorX + 'px' }"></div>
        <span class="output">{{ buffer.join('\n') }}</span>
    </div>
</template>

<script>
export default {
    name: 'TerminalPage',
    data() {
        return {
            buffer: [''],
            cursor: {
                x: 0,
                y: 0
            },
            charSize: {
                width: 11.73,
                height: 20
            },
            terminal: {
                foreground: '#fff',
                background: '#000'
            },
            inputPrompt: '~$ ',
            commands: {
                'help': (args) => {
                    this.printLn('Available commands:');
                    this.printLn('  help - show this help');
                    this.printLn('  clear - clear the screen');
                    this.printLn('  echo - echo the input');
                    this.printLn('  theme - change the theme');
                    this.printLn('  calculator - calculate numbers');
                },
                'clear': (args) => {
                    this.clear();
                },
                'echo': (args) => {
                    this.printLn(args.join(' '));
                },
                'theme': (args) => {
                    if (args.length === 0) {
                        this.printLn('Current theme: ' + localStorage.getItem('theme'));
                        this.printLn('Available themes: light, dark');
                    }
                    else if (args.length === 1) {
                        if (args[0] === 'light') {
                            localStorage.setItem('theme', 'light');
                            document.querySelector('html').dataset.bsTheme = 'light';
                        }
                        else if (args[0] === 'dark') {
                            localStorage.setItem('theme', 'dark');
                            document.querySelector('html').dataset.bsTheme = 'dark';
                        }
                        else {
                            this.printLn('Unknown theme: ' + args[0]);
                        }
                    }
                    else {
                        this.printLn('Usage: theme [light|dark]');
                    }
                },
                'calculator': (args) => {
                    if (args.length === 0) {
                        this.printLn('Usage: calculator [add|subtract|multiply|divide] [number] [number]');
                    }
                    else if (args.length === 3) {
                        const a = parseFloat(args[1]);
                        const b = parseFloat(args[2]);
                        if (args[0] === 'add') {
                            this.printLn(`${a} + ${b} = ${a + b}`);
                        }
                        else if (args[0] === 'subtract') {
                            this.printLn(`${a} - ${b} = ${a - b}`);
                        }
                        else if (args[0] === 'multiply') {
                            this.printLn(`${a} * ${b} = ${a * b}`);
                        }
                        else if (args[0] === 'divide') {
                            this.printLn(`${a} / ${b} = ${a / b}`);
                        }
                        else {
                            this.printLn('Unknown operation: ' + args[0]);
                        }
                    }
                    else {
                        this.printLn('Usage: calculator [add|subtract|multiply|divide] [number] [number]');
                    }
                }
            },
            scroll: {
                y: 0
            },
            currentHandler: null
        }
    },
    computed: {
        screenWidth() {
            return window.innerWidth / this.charSize.width;
        },
        screenHeight() {
            return window.innerHeight / this.charSize.height;
        },
        cursorX() {
            return this.cursor.x * this.charSize.width;
        },
        cursorY() {
            return (this.cursor.y * this.charSize.height) - this.scroll.y;
        }
    },
    mounted() {
        const run = async () => {
            this.printLn('PrevterOS v0.1.1');
            this.printLn('Type "help" for available commands');
            const running = true;
            while (running) {
                this.scrollToBottom();
                const input = await this.prompt(this.inputPrompt);
                this.scrollToBottom();

                if (input === '') continue;

                let found = false;
                for (const command in this.commands) {
                    if (input.startsWith(command)) {
                        const args = input.split(' ');
                        args.shift();
                        await this.commands[command](args);
                        found = true;
                    }
                }
                if (!found) {
                    this.printLn('Unknown command: ' + input.split(' ')[0]);
                }

            }
        }

        run();

        const output = document.querySelector('.output');
        output.addEventListener('scroll', () => {
            this.scroll.y = output.scrollTop;
        });

        document.querySelector('#mobileBox').addEventListener('input', (e) => {
            if (this.currentHandler) {
                let key = e.data;
                if (e.inputType === 'insertLineBreak') key = 'Enter';
                else if (e.inputType === 'deleteContentBackward') key = 'Backspace';
                this.currentHandler({ key });
            }
        });
    },
    methods: {
        scrollToBottom() {
            const output = document.querySelector('.output');
            output.scrollTop = output.scrollHeight;
        },
        focusMobile() {
            document.querySelector('#mobileBox').focus();
        },
        print(text) {
            this.buffer[this.cursor.y] += text;
            this.cursor.x += text.length;
        },
        printLn(text) {
            this.buffer[this.cursor.y] += text;
            this.buffer.push('');
            this.cursor.x += text.length;
            this.cursor.x = 0;
            this.cursor.y++;
        },
        async prompt(prefix) {
            this.print(prefix);
            this.scrollToBottom();
            const input = await this.read();
            return input;
        },
        async read() {
            return new Promise((resolve) => {
                const startCursorX = this.cursor.x;
                let done = false;
                const handler = (e) => {
                    if (done) return;

                    if (e.key === 'Enter') {
                        this.buffer.push('');
                        this.cursor.x = 0;
                        this.cursor.y++;
                        done = true;
                        // document.removeEventListener('keydown', handler);
                        this.currentHandler = null;
                        resolve(this.buffer[this.cursor.y - 1].slice(startCursorX));
                        this.scrollToBottom();
                    }
                    else if (e.key === 'ArrowRight') {
                        if (this.cursor.x < this.buffer[this.cursor.y].length) {
                            this.cursor.x++;
                        }
                        this.scrollToBottom();
                    }
                    else if (e.key === 'ArrowLeft') {
                        if (this.cursor.x > startCursorX) {
                            this.cursor.x--;
                        }
                        this.scrollToBottom();
                    }
                    else if (e.key === 'Backspace') {
                        if (this.cursor.x > startCursorX) {
                            this.buffer[this.cursor.y] = this.buffer[this.cursor.y].slice(0, this.cursor.x - 1) + this.buffer[this.cursor.y].slice(this.cursor.x);
                            this.cursor.x--;
                        }
                        this.scrollToBottom();
                    }
                    else if (e.key.length === 1) {
                        let key = e.key;
                        this.buffer[this.cursor.y] = this.buffer[this.cursor.y].slice(0, this.cursor.x) + key + this.buffer[this.cursor.y].slice(this.cursor.x);
                        this.cursor.x++;
                        this.scrollToBottom();
                    }
                }
                // document.addEventListener('keydown', handler);
                this.currentHandler = handler;
            });
        },
        clear() {
            this.buffer = [''];
            this.cursor.x = 0;
            this.cursor.y = 0;
        }
    }
}
</script>

<style>
:root {
    --term-page-bg: #ccc;
    --term-bg: #fff;
    --term-fg: #000;
}

[data-bs-theme='dark'] {
    --term-page-bg: #111;
    --term-bg: #000;
    --term-fg: #fff;
}

html,
#app {
    background-color: var(--term-page-bg);
}
</style>

<style scoped>
.terminal {
    display: flex;
    height: calc(100vh - 80px);
    font-size: 20px;
    color: var(--term-fg);
    background-color: var(--term-bg);
    overflow: hidden;
    margin: 40px;
    border-radius: 16px;
    padding: 0px;
    border: 10px solid var(--term-bg);
}

.output {
    white-space: pre;
    font-family: monospace;
    font-size: 20px;
    padding: 0;
    margin: 0;
    width: 100%;
    line-height: 20px;
    overflow-y: auto;
}

.cursor {
    position: relative;
    width: 1px;
    height: 20px;
    background-color: var(--term-fg);
    color: var(--term-bg);
    font-family: monospace;
    padding: 0;
    margin: 0;
    pointer-events: none;
    animation: blink 1s infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 768px) {
    .terminal {
        margin: 20px;
        height: calc(100vh - 40px);
    }
}

@media screen and (max-width: 576px) {
    .terminal {
        margin: 10px;
        height: calc(100vh - 20px);
    }
}

@media screen and (max-width: 400px) {
    .terminal {
        margin: 5px;
        height: calc(100vh - 10px);
    }
}
</style>