<template>
    <div class="d-flex flex-column justify-content-center align-items-center error">
        <div class="text-center">
            <h1 class="error404"><b>4</b><b>0</b><b>4</b></h1>
            <p>Sorry, the page you are looking for could not be found.</p>
            <p><RouterLink to="/" class="btn btn-outline-primary error-btn">Home page</RouterLink></p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageNotFound',
}
</script>

<style>
:root {
    --background-color: var(--bs-body-bg);
    --color-4: #000;
    --color-0: #728bdd;
    --stroke-thickness: 1.5px;
}

[data-bs-theme='dark'] {
    --color-4: #fff;
    --color-0: #728bdd;
}

.error {
    font-family: 'Roboto', sans-serif;
    background-color: var(--background-color);
    min-height: 75vh;
    padding: 0 1rem;
}

.error404 {
    line-height: 15rem;
    font-size: 15rem;
    font-weight: 700;
    color: var(--background-color);
    text-shadow: 0 0 5px var(--color-4);
    -webkit-text-stroke: var(--stroke-thickness) var(--color-4);
    text-stroke: var(--stroke-thickness) var(--color-4);
}

.error404 b:nth-child(2) {
    text-shadow: 0 0 5px var(--color-0);
    -webkit-text-stroke: var(--stroke-thickness) var(--color-0);
    text-stroke: var(--stroke-thickness) var(--color-0);
}

.error p {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

@media screen and (max-width: 576px) {
    .error404 {
        line-height: 10rem;
        font-size: 10rem;
    }
}

@media screen and (max-width: 400px) {
    .error404 {
        line-height: 8rem;
        font-size: 8rem;
    }
}
</style>
  